/* eslint-disable no-undef */

'use strict';

var tealium = require('./tealium');
var TRACKING_GLOBAL = 'tracking:global';

/**
 * openModalViewEvent fire view event on content component product click
 * @param {string} pid - getting parameter
 */
function openModalViewEvent(pid) {
    var productInfo = tealium.getProductDetails(pid);
    productInfo.then(function (result) {
        var data = window.utag_data;
        var productViewObj = {
            heirarchy: '',
            ip_address: data.ip_address,
            page_name: data.page_name,
            page_type: 'product',
            pdp_state: '',
            page_context_type: 'product',
            page_context_title: data.page_name,
            site_section: '',
            page_section: '',
            site_sub_section: '',
            site_sub_section_level3: '',
            site_sub_section_level4: '',
            site_sub_section_level5: '',
            error_text: '',
            site_type: data.site_type,
            country: data.country,
            language: data.language,
            locale: data.locale,
            login_type: data.login_type,
            geoPostal: data.geoPostal,
            promo_id: data.promo_id,
            promo_name: data.promo_name,
            promo_creative: data.promo_creative,
            promo_position: data.promo_position,
            order_currency: data.order_currency,
            user_flow: data.user_flow,
            viewport: data.viewport,
            customer_email: data.customer_email,
            hashed_email: data.hashed_email,
            user_anonymous: data.user_anonymous,
            user_authenticated: data.user_authenticated,
            user_registered: data.user_registered,
            customer_status: data.customer_status,
            customer_id: data.customer_id,
            customer_type: data.customer_type,
            customer_city: data.customer_city,
            customer_state: data.customer_state,
            order_subtotal: data.order_subtotal,
            product_name: result.product_name,
            product_brand: result.product_brand,
            product_unit_price: result.product_unit_price,
            product_sku: data.product_sku,
            product_style_number: result.product_style_number,
            product_id: result.product_master,
            adobe_product_id: data.adobe_product_id,
            product_master: result.product_master,
            product_category: result.product_category,
            product_subcategory: result.product_subcategory,
            product_quantity: result.product_quantity,
            product_vat: result.product_vat,
            product_gender: result.product_gender,
            product_color: result.product_color,
            product_size: result.product_size,
            product_width: result.product_width,
            product_gbu: result.product_gbu,
            product_line: result.product_line,
            product_type: result.product_type,
            product_silhouette: result.product_silhouette,
            product_coupon_discount: data.cart_product_coupon_discount,
            product_coupon_promo: data.cart_product_coupon_promo,
            category_path: result.category_path,
            original_product_unit_price: result.original_product_unit_price,
            empty_cart: data.empty_cart,
            cart_order_subtotal: data.cart_order_subtotal,
            cart_product_name: data.cart_product_name,
            cart_product_brand: data.cart_product_brand,
            cart_product_unit_price: data.cart_product_unit_price,
            cart_product_sku: data.cart_product_sku,
            cart_product_style_number: data.cart_product_style_number,
            cart_product_id: data.cart_product_master,
            adobe_cart_product_id: data.adobe_product_id,
            cart_product_variant_id: data.cart_product_variant_id,
            cart_product_master: data.cart_product_master,
            cart_product_category: data.cart_product_category,
            cart_product_quantity: data.cart_product_quantity,
            cart_product_vat: data.cart_product_vat,
            cart_product_coupon_discount: data.cart_product_coupon_discount,
            cart_product_coupon_promo: data.cart_product_coupon_promo,
            cart_product_gender: data.cart_product_gender,
            cart_product_color: data.cart_product_color,
            cart_product_size: data.cart_product_size,
            cart_product_width: data.cart_product_width,
            cart_product_gbu: data.cart_product_gbu,
            cart_product_line: data.cart_product_line,
            cart_product_silhouette: data.cart_product_silhouette,
            cart_product_type: data.cart_product_type
        };
        tealium.triggerEvent(productViewObj, 'view');
    });
}
module.exports = {
    init: function () {
        $(document).on(TRACKING_GLOBAL, '.product-tile .tile-image:not(.disabled), .product-tile .nb-button:not(.disabled), .product-tile .pname, .product-tile .swatches .slick-slide', function () {
            if (!window.utag_data) {
                return;
            }

            var $this = $(this);
            var pid;
            var pgptiles = $this.closest('.pgptiles');

            if ($this.hasClass('nb-button')) {
                pid = $this.data('pid');
            } else {
                pid = $this.closest('.product').data('pid');
            }

            var position = pgptiles.data('product-position') || '';
            var elemType = $(this)[0].nodeName;
            var productInfo = tealium.getProductDetails(pid);
            var productElement = $(this).children(':first').children(':first');
            var productSKU = [productElement.attr('title')];
            var productColor = [productElement.children(':first').children(':first').data('colorName')];

            var catPath = [];

            if (window.utag_data.category_path) {
                catPath.push(window.utag_data.category_path);
            } else if (window.utag_data.page_type === 'search' && window.utag_data.search_term && window.utag_data.search_term !== '') {
                catPath.push('Search Results > ' + window.utag_data.search_term);
            }

            productInfo.then(function (data) {
                var productGridObject = {
                    event_type: 'product_click',
                    page_name: window.utag_data.page_name,
                    adobe_user_flow: 'product detail',
                    item_category: data.product_category,
                    item_category2: data.product_line,
                    item_category3: data.product_gender,
                    item_category4: productColor,
                    item_category5: data.product_style_number,
                    item_variant: data.product_variant_id,
                    product_name: data.product_name,
                    product_brand: data.product_brand,
                    product_unit_price: data.product_unit_price,
                    product_sku: productSKU,
                    product_style_number: data.product_style_number,
                    product_id: data.product_master,
                    product_master: data.product_master,
                    product_category: data.product_category,
                    product_subcategory: data.product_subcategory,
                    product_quantity: data.product_quantity,
                    product_vat: data.product_vat,
                    product_gender: data.product_gender,
                    product_color: productColor,
                    product_size: data.product_size,
                    product_width: data.product_width,
                    product_gbu: data.product_gbu,
                    product_line: data.product_line,
                    product_type: data.product_type,
                    product_silhouette: data.product_silhouette,
                    category_path: catPath,
                    product_list: catPath,
                    product_position: [position.toString()],
                    ga_action: 'Product Click',
                    ga_label: pid,
                    original_product_unit_price: data.original_product_unit_price
                };
                tealium.triggerEvent(productGridObject);
            });

            productInfo.then(function (data) {
                var productGridObject = {
                    event_type: 'select_item',
                    item_brand: data.product_brand,
                    item_category: data.product_category,
                    item_category2: data.product_line,
                    item_category3: data.product_gender,
                    item_category4: productColor,
                    item_category5: data.product_style_number,
                    item_id: data.product_id,
                    item_id_price: data.product_unit_price,
                    item_list_id: window.utag_data.item_list_id,
                    item_name: data.product_name,
                    item_list_name: catPath,
                    item_variant: data.product_variant_id ? data.product_variant_id : data.product_id,
                    pdp_availability: data.product_availability,
                    price: data.product_unit_price,
                    quantity: data.product_quantity,
                    page_name: window.utag_data.page_name,
                    page_group: 'product listing',
                    page_context_type: data.page_context_type !== undefined ? data.page_context_type : window.utag_data.page_context_type,
                    page_context_title: data.page_context_title !== undefined ? data.page_context_type : window.utag_data.page_context_title,
                    site: data.site !== undefined ? data.site : window.utag_data.site,
                    site_section: 'product listing',
                    site_type: data.site_type !== undefined ? data.site_type : window.utag_data.site_type,
                    login_status: window.utag_data.customer_status,
                    user_id: data.user_id !== undefined ? data.user_id : window.utag_data.user_id,
                    index: position,
                    element_type: elemType,
                    element_text: data.product_name,
                    product_brand: data.product_brand,
                    product_category: data.product_category,
                    product_color: productColor,
                    product_gender: data.product_gender,
                    product_id: data.product_master,
                    product_impression_id: catPath,
                    product_line: data.product_line,
                    product_list: catPath,
                    product_master: data.product_master,
                    product_name: data.product_name,
                    product_quantity: data.product_quantity,
                    product_size: data.product_size,
                    product_sku: productSKU,
                    product_silhouette: data.product_silhouette,
                    product_style_number: data.product_style_number,
                    product_subcategory: data.product_subcategory,
                    category_path: data.category_path,
                    original_product_unit_price: data.original_product_unit_price
                };
                tealium.triggerEvent(productGridObject);
            });
        });

        $(document).on(TRACKING_GLOBAL, '.experience-commerce_assets-ProductGrid .select-size-wrapper .nb-button', function () {
            var $this = $(this);
            var productID = $this.attr('data-pid');
            if (window.utag_data) {
                var prodObj = {
                    event_type: 'select_size_shop_look',
                    page_name: window.utag_data.page_name,
                    event_message: 'Select Size',
                    ga_action: 'Shop | Select Size',
                    ga_label: productID
                };
                tealium.triggerEvent(prodObj);
            }
        });

        $(document).on(TRACKING_GLOBAL, '.experience-commerce_assets-horizontalCategoryCarousel .slide-arrow.next-arrow, .experience-commerce_assets-horizontalCategoryCarousel .slide-arrow.prev-arrow', function () {
            if (window.utag_data) {
                var carouselObj = {
                    event_type: 'carousel_click',
                    page_name: window.utag_data.page_name,
                    event_message: 'Carousel Arrow Click',
                    ga_action: 'Carousel Arrow Click',
                    ga_label: ''
                };
                tealium.triggerEvent(carouselObj);
            }
        });

        $(document).on(TRACKING_GLOBAL, '.experience-commerce_assets-ProductStack .experiencestack-btn', function () {
            if (window.utag_data) {
                var ctaObj = {
                    event_type: 'see_full_collection',
                    page_name: window.utag_data.page_name,
                    event_message: 'Shop | See Collection',
                    ga_action: 'Shop | See Collection',
                    ga_label: ''
                };
                tealium.triggerEvent(ctaObj);
            }
        });

        var chatEvent = function () {
            if (window.utag_data) {
                var chatObj = {
                    event_type: 'Help Widget',
                    page_name: window.utag_data.page_name,
                    event_message: 'Help Chat Widget',
                    ga_action: 'Help Widget',
                    ga_label: window.utag_data.page_type
                };
                tealium.triggerEvent(chatObj);
            }
        };

        $(document).on(TRACKING_GLOBAL, '.needhelp.js-needhelp .need-help', chatEvent);
        $(document).on('zendesk:open', chatEvent);

        $(document).on('shop-the-look:showcollection', function () {
            if (!window.utag_data) {
                return;
            }

            var prodObj = {
                event_type: 'shop_the_look',
                page_name: window.utag_data.page_name,
                event_message: 'Shop The Look | See Collection',
                ga_action: 'Shop | See Collection',
                ga_label: ''
            };
            tealium.triggerEvent(prodObj);
        });

        $(document).on('shop-the-look:selectSize', function (event, data) {
            if (window.utag_data) {
                var prodObj = {
                    event_type: 'select_size_shop_look',
                    page_name: window.utag_data.page_name,
                    event_message: 'Select Size',
                    ga_action: 'Shop | Select Size',
                    ga_label: data
                };
                tealium.triggerEvent(prodObj);
                openModalViewEvent(data);
            }
        });

        $(document).on(TRACKING_GLOBAL, '.product-tile .wishListToggle .add-to-wish-list', function () {
            var pid = $(this).closest('.image-container').find('.product-id').text();
            var productInfo = tealium.getProductDetails(pid);
            var elemText = 'wishlist svg';
            var elemType = $(this)[0].nodeName;

            productInfo.then(function (data) {
                $(document).trigger('wishlist:add-product', {
                    product_data: data,
                    event_type: 'pgp_move_to_wishlist',
                    ga_action: 'Move to Wishlist | PGP',
                    ga_label: pid,
                    element_text: elemText,
                    element_type: elemType
                });

                if (window.utag_data && window.utag_data.user_authenticated === 'false') {
                    $(document).trigger('login-modal-open', ['login_popupin', 'wishlist_pgp']);
                }
            });
        });
    }
};
